import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import FullPageSpinner from "../../Components/FullPageSpinner";

function AuthCheck() {
  const { loading, isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && !isAuth()) {
      const timer = setTimeout(() => {
        navigate("/login", { state: { from: location } });
      }, 1000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [loading, isAuth, navigate, location]);

  if (loading) {
    return <FullPageSpinner />;
  }

  return isAuth() ? <Outlet /> : null;
}

export default AuthCheck;
