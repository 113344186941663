import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

function MiddleBanner({ imgUrl }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  if (isLoading) {
    // preloader with card structure
    return (
      <Container>
        <div className="card sn-card-preloader">
          <div className="card-img-top"></div>
        </div>
      </Container>
    );
  }
  return (
    <Container>
      <div className="card sn-banner-card">
        <img src={imgUrl} alt="" className="img-fluid" />
      </div>
    </Container>
  );
}

export default MiddleBanner;
