import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { Avatar } from "@mui/material";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

function ProfileCard() {
  const { user } = useAuth();
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="me-3">
          <Avatar />
        </div>
        <div>
          <h5>{`${user.name}`}</h5>
          <p>{user.email}</p>
        </div>
      </div>
      <div>
        <h6>Address Information</h6>
        <p>{user.address}</p>
      </div>
      {/* Add more fields here */}
      <RouteLinkWithLoading
        to="/my/orders"
        className="btn btn-primary w-100 me-2"
      >
        My Orders
      </RouteLinkWithLoading>
      <RouteLinkWithLoading
        to="/my/cart"
        className="btn btn-primary w-100 mt-2"
      >
        My Cart
      </RouteLinkWithLoading>
      {user.role === "admin" || user.role === "it" ? (
        <a
          href="https://admin.sakshoppers.com/"
          className="btn btn-primary w-100 mt-2"
        >
          Admin dashboard
        </a>
      ) : (
        ""
      )}
    </>
  );
}

export default ProfileCard;
