import React from "react";
import HomepageHero from "../Components/HomepageHero";
import HomepageCategories from "../Components/HomepageCategories";
import FeaturedProduct from "../Components/FeaturedProduct";
import MiddleBanner from "../Components/MiddleBanner";
import ForYouProduct from "../Components/ForYouProduct";
import TestimonialSection from "../Components/TestimonialSection";
import { Container } from "react-bootstrap";
import { CardGiftcard, LocalShipping, Support } from "@mui/icons-material";
import bannerArraival from "../Assets/img/Arrival-Home-Page-Banner.jpg";
import bannerDiscount from "../Assets/img/Discount-Home-Page-Banner.jpg";
import bannerTopRated from "../Assets/img/Top-rated-Home-Page-Banner.jpg";
import bannerBestSelling from "../Assets/img/Best-Selling-Home-Page-Banner.jpg";
import WhatsappIcon from "./Includes/WhatsappIcon";

function Home() {
  return (
    <div>
      <WhatsappIcon />
      <HomepageHero />
      <MiddleBanner imgUrl={bannerDiscount} />
      <section>
        <Container>
          <div className="sn-main-card mt-0 pt-0">
            <div className="sn-main-content pt-0">
              <div className="row">
                <div className="col-md-4">
                  <div className="feature-box">
                    <div className="fbox-icon">
                      <div className="icon-inner">
                        <LocalShipping />
                      </div>
                    </div>
                    <div className="fbox-content">
                      <h3 className="ourservice-heading">
                        Free Delivery in Mbarara
                      </h3>
                      <p className="description">
                        This only applies on products not more than 10kgs
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="feature-box">
                    <div className="fbox-icon">
                      <div className="icon-inner">
                        <CardGiftcard />
                      </div>
                    </div>
                    <div className="fbox-content">
                      <h3 className="ourservice-heading">Top Rated Quality</h3>
                      <p className="description">
                        Our products are genuine and high quality for your
                        satisfaction
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="feature-box">
                    <div className="fbox-icon">
                      <div className="icon-inner">
                        <Support />
                      </div>
                    </div>
                    <div className="fbox-content">
                      <h3 className="ourservice-heading">
                        Friendly Support 24/7
                      </h3>
                      <p className="description">
                        We ready to help you on anything to do with Sak Shoppers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <HomepageCategories />
      <MiddleBanner imgUrl={bannerArraival} />
      <FeaturedProduct />
      <MiddleBanner imgUrl={bannerTopRated} />
      <ForYouProduct />
      <MiddleBanner imgUrl={bannerBestSelling} />
      <TestimonialSection />
    </div>
  );
}

export default Home;
