import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import ProductGrid from "./Includes/ProductGrid";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import { useLocation } from "react-router-dom";
import FullPageSpinner from "../Components/FullPageSpinner";

function SearchProductsPage() {
  const { apiUrl, setToastMessage } = useBasicDetails();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUrl + "search-products", {
        params: {
          query: query,
        },
      });
      const data = response.data;
      if (data.status === true) {
        setSearchResults(data.data.products.data);
      } else {
        setToastMessage({
          message: data.message,
          isError: true,
        });
      }
    } catch (error) {
      setToastMessage({
        message: "Something went wrong!",
        isError: true,
      });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (query !== null && query !== "") {
      console.log(query);
      if (!loading) handleSearch();
    } else setSearchResults([]);
  }, [query]); // Trigger search on searchTerm change
  if (loading) {
    return <FullPageSpinner />;
  }
  return (
    <section>
      <Container>
        <Row>
          <Col md={12}>
            <div className="sn-main-card">
              <div className="sn-main-title d-flex">
                <div className="w-100">Search Results</div>
              </div>
              <div className="sn-main-content">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {searchResults.length > 0 ? (
                      <ProductGrid products={searchResults} />
                    ) : (
                      <p>No products found.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SearchProductsPage;
