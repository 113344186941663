import React from "react";
import { Table, Button, Image } from "react-bootstrap";
import { useCart } from "../../Context/CartContext";

import { ToastContainer, toast } from "react-toastify";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useCart();
  const { currency } = useBasicDetails();

  // Function to calculate the total price after discount
  const calculateTotalAfterDiscount = () => {
    return cartItems.reduce((total, item) => {
      const itemPrice = parseFloat(item.product.price);
      const itemDiscount = parseFloat(item.product.discount);
      const discountedPrice = itemPrice - (itemPrice * itemDiscount) / 100;
      return total + discountedPrice * item.quantity;
    }, 0);
  };
  console.log(cartItems);

  return (
    <div className="container mt-5">
      <ToastContainer />
      <h1>Your Cart</h1>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div style={{ overflowX: "scroll" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Image</th>
                <th style={{ minWidth: "200px" }}>Name</th>
                <th>Quantity</th>
                <th>Original Price</th>
                <th>Price (After Discount)</th>
                <th>Total Price (After Discount)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item) => (
                <tr key={item.product.slug}>
                  <td style={{ maxWidth: "150px" }}>
                    <Image
                      src={item.product.feature_image}
                      alt={item.product.title}
                      thumbnail
                      height={50}
                    />
                  </td>
                  <td>
                    <RouteLinkWithLoading to={`/product/${item.product.slug}`}>
                      <Button variant="link">{item.product.title}</Button>
                    </RouteLinkWithLoading>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => {
                        const maxQty = item.product.stock_quantity;
                        const minQty = 0;
                        const selectedQty = e.target.value;
                        if (maxQty < selectedQty) {
                          return toast.error("Allowed max quantity: " + maxQty);
                        }
                        if (minQty > selectedQty) {
                          return toast.error("Allowed min quantity: " + minQty);
                        }
                        updateQuantity(
                          item.product.slug,
                          parseInt(e.target.value)
                        );
                      }}
                      min="1"
                      max={item.product.stock_quantity} // Assuming stock quantity is available
                      aria-label="Quantity"
                    />
                  </td>
                  <td>${parseFloat(item.product.price).toFixed(2)}</td>
                  <td>
                    {currency}
                    {parseFloat(
                      item.product.price -
                        (item.product.price * item.product.discount) / 100
                    ).toFixed(2)}
                  </td>
                  <td>
                    {currency}
                    {parseFloat(
                      (item.product.price -
                        (item.product.price * item.product.discount) / 100) *
                        parseFloat(item.quantity)
                    )}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => removeFromCart(item.product.slug)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {cartItems.length > 0 && (
        <div className="mt-3">
          <div className="d-flex">
            <div className="ms-auto">
              <h4>
                Total (After Discount): {currency}
                {calculateTotalAfterDiscount().toFixed(2)}
              </h4>
              <RouteLinkWithLoading
                className="btn btn-primary"
                to={"/my/cart/checkout"}
              >
                Checkout
              </RouteLinkWithLoading>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
