import React, { useEffect, useState } from "react";

import { useBasicDetails } from "../Context/BasicDetailsContext";
import { Col, Row } from "react-bootstrap";
import RouteLinkWithLoading from "./RouteLinkWithLoading";
function ProductListCard({ product }) {
  const { currency } = useBasicDetails();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  if (loading) {
    // preloader with card structure
    return (
      <div className="card sn-card-preloader">
        <Row>
          <Col md={4} xs={12}>
            <div className="card-img-top"> </div>
          </Col>
          <Col md={8} xs={12}>
            <div className="card-body">
              <h5 className="card-title placeholder-title"> </h5>

              <div className="card-img-top mb-2" style={{ height: "60px" }}>
                {" "}
              </div>
              <p className="card-text placeholder-text"> </p>
              <div className="d-flex justify-content-between">
                <span className="placeholder-text"> </span>
                <span className="placeholder-text"> </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  const slicedTitle =
    product.title.length > 30
      ? product.title.slice(0, 30) + "..."
      : product.title;
  const price = product.price ? parseFloat(product.price) : 0;
  const discount = product.discount ? parseFloat(product.discount) : 0;
  const discountedPrice = price - (price * discount) / 100;
  // Function to truncate HTML content
  const truncateHtml = (html, maxLength) => {
    const truncatedHtml = new DOMParser().parseFromString(html, "text/html");
    const textContent = truncatedHtml.body.textContent || "";
    return textContent.slice(0, maxLength);
  };

  // Truncate the HTML content to a specified length (e.g., 100 characters)
  const truncatedDescription = truncateHtml(product.description, 200);

  return (
    <RouteLinkWithLoading
      to={`/product/${product.slug}`}
      className="card sn-product-card"
    >
      <Row>
        <Col md={4} xs={12}>
          <div className="sn-card-img">
            <img
              src={product.feature_image}
              className="card-img-top"
              alt={product.title}
            />
          </div>
        </Col>
        <Col md={8} xs={12} className="d-flex" style={{ flexWrap: "wrap" }}>
          <div className="card-body w-100">
            <h3 className="">{slicedTitle}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: truncatedDescription }}
              className="sn-shor-content"
            />
          </div>
          <div className="mt-auto w-100 px-3 py-3">
            <div className="d-flex justify-content-between">
              {discount > 0 ? (
                <>
                  <del>
                    {" "}
                    <span className="text-muted">
                      {currency}
                      {price}
                    </span>
                  </del>
                  <span className="text-success">
                    {currency}
                    {discountedPrice}
                  </span>
                </>
              ) : (
                <span className="text-muted">
                  {currency}
                  {price}
                </span>
              )}
            </div>
          </div>
          {discount > 0 ? (
            <div className="sn-offer-badge">{discount}% off</div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </RouteLinkWithLoading>
  );
}

export default ProductListCard;
