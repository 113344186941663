import React from "react";
import { Card } from "react-bootstrap";
import { Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const TestimonialCard = ({ testimonial }) => {
  return (
    <Card className="testimonial-card">
      <Card.Body className="d-flex align-items-center">
        <div className="testimonial-content">
          <Card.Text>{testimonial.text}</Card.Text>
          <Card.Title className="text-center sn-ttitle mt-4">
            <Avatar
              sx={{ backgroundColor: "var(--sn-primary)" }}
              className="testimonial-icon"
            >
              <PersonIcon />
            </Avatar>
            {testimonial.author}
          </Card.Title>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TestimonialCard;
