import React, { useState } from "react";
import { Button, Form, Card, ListGroup, Row, Col } from "react-bootstrap";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import "../Assets/productdetail.css";

const ReviewsSection = ({
  reviews,
  newReview,
  handleReviewChange,
  handleReviewSubmit,
  reviewError,
  handleDeleteReview,
  user,
}) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleStarClick = (rating) => {
    handleReviewChange({ target: { name: "rating", value: rating } });
  };

  const handleStarHover = (rating) => {
    setHoverRating(rating);
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(
      new Date(dateString)
    );
  };
  return (
    <Card className="sn-main-card">
      <Card.Body className="sn-main-content">
        <h2>Write a Review</h2>
        <Form onSubmit={handleReviewSubmit}>
          <Form.Group>
            <Form.Label>Rating</Form.Label>
            <div>
              {Array.from({ length: 5 }, (_, index) => {
                const ratingValue = index + 1;
                return (
                  <span
                    key={ratingValue}
                    onClick={() => handleStarClick(ratingValue)}
                    onMouseEnter={() => handleStarHover(ratingValue)}
                    onMouseLeave={() => setHoverRating(0)}
                    style={{ cursor: "pointer" }}
                  >
                    {ratingValue <= (hoverRating || newReview.rating) ? (
                      <StarIcon />
                    ) : (
                      <StarBorderIcon />
                    )}
                  </span>
                );
              })}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              as="textarea"
              name="comment"
              value={newReview.comment}
              onChange={handleReviewChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit Review
          </Button>
          {reviewError && (
            <p className="text-danger mt-2">Error: {reviewError}</p>
          )}
        </Form>
        <h2 className="mt-4">Reviews</h2>
        {reviews.length === 0 ? (
          <p>No reviews yet.</p>
        ) : (
          <ListGroup>
            {reviews.map((review) => (
              <ListGroup.Item key={review.id}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="mr-2">
                      {Array.from({ length: 5 }, (_, index) =>
                        index < review.rating ? (
                          <StarIcon key={index} />
                        ) : (
                          <StarBorderIcon key={index} />
                        )
                      )}
                    </span>
                    <p className="text-muted ms-auto mb-0">
                      Created on: {formatDate(review.created_at)}
                    </p>
                  </div>
                  {review.user && user ? (
                    review.user.id == user.id ? (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDeleteReview(review.id, review.user.id)
                        }
                        className="text-danger"
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <p className="ml-2 mb-0 mt-2">{review.comment}</p>
                <p className="text-muted mb-0">By: {review.user.name}</p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Card.Body>
    </Card>
  );
};

export default ReviewsSection;
