import React, { useState } from "react";
import { useLoading } from "../Context/LoadingContext";
import { Link } from "react-router-dom";

const RouteLinkWithLoading = ({ to, className, handleClose, children }) => {
  const { showLoading, hideLoading } = useLoading();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    showLoading();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    console.log("here");
    setTimeout(() => {
      setIsLoading(false);
      hideLoading();
    }, 1000);
  };

  return (
    <span onClick={handleClick}>
      <Link to={to} className={className} onClick={handleClose}>
        {isLoading ? "Loading..." : children}
      </Link>
    </span>
  );
};

export default RouteLinkWithLoading;
