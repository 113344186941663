import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import FullPageSpinner from "../../Components/FullPageSpinner";
import axios from "axios";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
import { ToastContainer, toast } from "react-toastify";
import ProfileCard from "./ProfileCard";

function ProfileManage() {
  const { user } = useAuth();
  const { token } = useAuth();
  const { apiUrl } = useBasicDetails();

  const [formData, setFormData] = useState({
    name: user.name || "",
    phone: user.phone || "",
    short_bio: user.short_bio || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiUrl + "account/update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
    } catch (error) {
      toast.error("error uploading data");
    }
  };

  useEffect(() => {}, [user]);

  if (!user) {
    return <FullPageSpinner />;
  }

  return (
    <Container className="mb-3">
      <ToastContainer />
      <Row className="mt-4">
        <Col md={12}>
          <h1 className="mb-3">My Account</h1>
          <hr />
        </Col>
        <Col xs={12} md={4} className="sn-myac-sidebar">
          <ProfileCard />
        </Col>

        <Col xs={12} md={8} className="pt-5">
          <h3>Update Profile</h3>
          <Form method="post" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={user.email}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Short Bio</Form.Label>
              <Form.Control
                type="text"
                name="short_bio"
                value={formData.short_bio}
                onChange={handleInputChange}
              />
            </Form.Group>
            {/* Add more fields here */}
            <Button type="submit" variant="primary" className="mt-3">
              Update Profile
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileManage;
