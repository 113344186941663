// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#007bff"); // Default Bootstrap primary color
  const [secondaryColor, setSecondaryColor] = useState("#6c757d"); // Default Bootstrap secondary color

  useEffect(() => {
    // Update the HTML attribute for the Bootstrap theme
    const storedTheme = localStorage.getItem("theme");

    if (storedTheme) {
      setIsDarkTheme(storedTheme === "true");
      document.documentElement.setAttribute(
        "data-bs-theme",
        storedTheme === "true" ? "dark" : "light"
      );
    }
  }, []);

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
    localStorage.setItem("theme", (!isDarkTheme).toString());
    document.documentElement.setAttribute(
      "data-bs-theme",
      !isDarkTheme ? "dark" : "light"
    );
  };

  const setThemeColors = (primary, secondary) => {
    setPrimaryColor(primary);
    setSecondaryColor(secondary);
  };

  return (
    <ThemeContext.Provider
      value={{
        isDarkTheme,
        toggleTheme,
        primaryColor,
        secondaryColor,
        setThemeColors,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export { ThemeProvider, useTheme };
