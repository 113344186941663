import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import ProductFilter from "./Includes/ProductFilter";
import axios from "axios";
import CategoryProductPreloader from "./Includes/CategoryProductPreloader";
import ProductGrid from "./Includes/ProductGrid";
import { GridView, List } from "@mui/icons-material";
import ProductList from "./Includes/ProductList";

function CategoryProducts() {
  const { apiUrl, setToastMessage } = useBasicDetails();
  const { categorySlug } = useParams();
  const [preloading, setPreloading] = useState(true);
  const [prevCatSlug, setPrevCatSlug] = useState(null);
  const [productLoading, setproductLoading] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [filters, setFilters] = useState([]);
  const [gridView, setGridView] = useState(true);
  const [products, setProducts] = useState({
    current_page: 1,
    data: [],
    first_page_url: apiUrl + "get-filter?page=1",
    from: 1,
    last_page: 1,
    last_page_url: apiUrl + "get-filter?page=1",
    links: [],
    next_page_url: null,
    path: apiUrl + "get-filter",
    per_page: 15,
    prev_page_url: null,
    to: 2,
    total: 2,
  });

  const findCategory = async (slug) => {
    try {
      const url = apiUrl + "get-filter";
      const response = await axios.get(url, {
        params: {
          categorySlug: slug,
        },
      });
      const datas = response.data;
      if (datas.status === true) {
        setTimeout(() => {
          setFilters(datas.data.filters);
          setProducts(datas.data.products);
          setPreloading(false);
          return datas.products;
        }, 1000);
      }
      setTimeout(() => {
        setPreloading(false);
        return setToastMessage({
          message: datas.message,
          isError: true,
        });
      }, 1000);
    } catch (error) {
      // Handle error
      setTimeout(() => {
        setPreloading(false);
        return setToastMessage({
          message: "Something went wrong!",
          isError: true,
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (prevCatSlug !== categorySlug) {
      setPreloading(true);
    }
    if (preloading && categorySlug && prevCatSlug !== categorySlug) {
      setPrevCatSlug(categorySlug);
      findCategory(categorySlug);
    }
  }, [preloading, categorySlug]);
  const handlePageChange = async (cPpage = 1) => {
    try {
      setproductLoading(true);

      const url = apiUrl + "filter-product";
      const response = await axios.get(url, {
        params: {
          categorySlug: categorySlug,
          page: cPpage,
          filters: JSON.stringify(filterValue),
        },
      });

      const datas = response.data;
      if (datas.status === true) {
        setProducts(datas.data.products);
      } else {
        setToastMessage({
          message: datas.message,
          isError: true,
        });
      }

      setproductLoading(false);
    } catch (error) {
      setproductLoading(false);
      setToastMessage({
        message: "Something went wrong!",
        isError: true,
      });
    }
  };
  if (preloading) {
    return <CategoryProductPreloader />;
  }
  return (
    <section>
      <Container>
        <Row>
          <Col md={4} lg={3}>
            <ProductFilter
              setPreloading={setPreloading}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              filters={filters}
              handlePageChange={handlePageChange}
            />
          </Col>
          <Col md={8} lg={9}>
            <div className="sn-main-card">
              <div className="sn-main-title d-flex">
                <div className="w-100">Product List </div>
                <div className="d-flex me-auto sn-view-btn-cont">
                  <button
                    type="button"
                    className={`btn sn-view-btn ${gridView ? "active" : ""}`}
                    onClick={() => setGridView(true)}
                  >
                    <GridView />
                  </button>

                  <button
                    type="button"
                    className={`btn sn-view-btn ${gridView ? "" : "active"}`}
                    onClick={() => setGridView(false)}
                  >
                    <List />
                  </button>
                </div>
              </div>
              {!productLoading && (
                <>
                  <div className="sn-main-content">
                    {gridView ? (
                      <ProductGrid products={products.data} />
                    ) : (
                      <ProductList products={products.data} />
                    )}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    {products.last_page > 1 && (
                      <Pagination>
                        {Array.from(
                          { length: products.last_page },
                          (_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === products.current_page}
                              onClick={(e) => {
                                e.preventDefault();
                                return handlePageChange(index + 1);
                              }}
                            >
                              {index + 1}
                            </Pagination.Item>
                          )
                        )}
                      </Pagination>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CategoryProducts;
