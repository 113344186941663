import React from "react";
import heroImage from "../Assets/img/about-hero.jpg"; // Correct import path for heroImage
import "../Assets/about.css";
import { Favorite, Handshake, WorkspacePremium } from "@mui/icons-material";
function About() {
  return (
    <>
      <section
        className="sn-about-hero"
        style={{
          backgroundImage: `linear-gradient(to left, #00000096, #00000096), url('${heroImage}')`,
        }}
      >
        <div className="container">
          <div className="sn-about-hero-inner">
            <div className="text-center">
              <h1 className="sn-about-title">
                <span>About Us</span>
              </h1>
              <h3 className="sn-about-sub-title">
                Delivering happiness on the go!
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="sn-our-story">
        <div className="container">
          <div className="sn-our-story-content">
            <h2>WE PRIDE IN GIVING A CONVENIENT SHOPPING EXPERIENCE</h2>
            <p>
              Are you an online shopper? Forget about all the inconveniences you
              could have experienced somewhere else. This is Western Uganda’s
              most trusted shopping site that helps in shoppers find what they
              are looking for in one place with utmost ease.
            </p>
            <p>
              Our payment methods are MTN Mobile Money and Airtel Money, which
              makes transaction process instant, secure, and convenient. Our
              customer care team is always on set to give you prompt response at
              any time of need and also give you updates on your orders.
            </p>
          </div>
        </div>
      </section>
      <section className="sn-our-values">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="sn-section-title">Our Values</h2>
            </div>
            <div className="col-md-4">
              <div className="sn-value">
                <h2>Quality</h2>
                <p>
                  We are committed to delivering the highest quality products.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sn-value">
                <h2>Customer Satisfaction</h2>
                <p>Our customers' satisfaction is our top priority.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sn-value">
                <h2>Innovation</h2>
                <p>We strive for continuous innovation and improvement.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sn-our-promise">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="sn-section-title">Our Promise</h2>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="sn-promise-item">
                <div className="sn-promise-icon">
                  <Handshake />
                </div>
                <h3>Commitment</h3>
                <p>
                  We are committed to providing excellent service and quality
                  products.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="sn-promise-item">
                <div className="sn-promise-icon">
                  <Favorite />
                </div>
                <h3>Customer Satisfaction</h3>
                <p>
                  Ensuring customer satisfaction is our top priority in every
                  interaction.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sn-promise-item">
                <div className="sn-promise-icon">
                  <WorkspacePremium />
                </div>
                <h3>Excellence</h3>
                <p>
                  Striving for excellence in everything we do, from product
                  quality to customer service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
