import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Master from "./Pages/Laouts/Master";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "./Context/ThemeContext";
import "./Assets/main.scss";
import "./Assets/style.css";
import { useEffect, useState } from "react";
import { BasicDetailsProvider } from "./Context/BasicDetailsContext";
import ProductList from "./Pages/ProductList";
import CategoryProducts from "./Pages/CategoryProducts";
import ProductDetails from "./Pages/ProductDetails";
import Register from "./Pages/Auth/Register";
import Login from "./Pages/Auth/Login";
import { AuthProvider } from "./Context/AuthContext";
import CartPage from "./Pages/User/CartPage";
import AuthCheck from "./Pages/User/AuthCheck";
import ProfileManage from "./Pages/User/ProfileManage";
import { LoadingProvider } from "./Context/LoadingContext";
import Contact from "./Pages/Contact";
import Faqs from "./Pages/StaticPages/Faqs";
import HowToBuy from "./Pages/StaticPages/HowToBuy";
import ReturnsRefunds from "./Pages/StaticPages/ReturnsRefunds";
import TermsAndConditions from "./Pages/StaticPages/TermsAndConditions";
import PrivacyPolicy from "./Pages/StaticPages/PrivacyPolicy";
import { CartProvider } from "./Context/CartContext";
import Checkout from "./Pages/User/Checkout";
import InvoicePage from "./Pages/User/InvoicePage";
import MyOrders from "./Pages/User/MyOrders";
import SearchProductsPage from "./Pages/SearchProductsPage";
import FullPageSpinner from "./Components/FullPageSpinner";

function App() {
  const isDesktop = window.innerWidth >= 768;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (isDesktop) {
      const link = document.createElement("link");
      link.href = "/desktop.css";
      link.rel = "stylesheet";
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [isDesktop]);

  return (
    <div className="app-container">
      <ThemeProvider>
        <BasicDetailsProvider>
          <AuthProvider>
            <CartProvider>
              <LoadingProvider>
                <Routes>
                  <Route path="/" element={<Master />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/contact-us" element={<Contact />} />
                    {/* static pages */}
                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/how-to-buy" element={<HowToBuy />} />
                    <Route
                      path="/returns-refunds"
                      element={<ReturnsRefunds />}
                    />
                    <Route
                      path="/term-condition"
                      element={<TermsAndConditions />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    {/* End static pages */}

                    <Route path="/products" element={<ProductList />} />
                    <Route
                      path="/search-results"
                      element={<SearchProductsPage />}
                    />
                    <Route
                      path="/category/:categorySlug"
                      element={<CategoryProducts />}
                    />
                    <Route
                      path="/product/:productSlug"
                      element={<ProductDetails />}
                    />

                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    <Route path="/my" element={<AuthCheck />}>
                      <Route path="/my" element={<ProfileManage />} />
                      <Route path="/my/cart" element={<CartPage />} />
                      <Route path="/my/cart/checkout" element={<Checkout />} />
                      <Route path="/my/invoice/" element={<InvoicePage />} />
                      <Route path="/my/orders" element={<MyOrders />} />
                    </Route>
                  </Route>

                  {/* <Route path="/my/*" element={<UserRoutes />} /> */}
                  <Route path="/404" element={<Home />} />
                </Routes>
              </LoadingProvider>
            </CartProvider>
          </AuthProvider>
        </BasicDetailsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
