import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductGallery from "./Includes/ProductGallery";
import { useBasicDetails } from "../Context/BasicDetailsContext";
import axios from "axios";
import "../Assets/productdetail.css";
import { useCart } from "../Context/CartContext";
import { ToastContainer, toast } from "react-toastify";
import { Button, Col, Container, Row } from "react-bootstrap";
import ProductCard from "../Components/ProductCard";
import ReviewsSection from "../Components/ReviewsSection";
import { useAuth } from "../Context/AuthContext";

function ProductDetails() {
  const { addToCart, isProductInCart, buyNowCart } = useCart();
  const { productSlug } = useParams();
  const { apiUrl, currency, isMobile } = useBasicDetails();
  const navigate = useNavigate();
  const { token, isAuth, user } = useAuth();

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ rating: "", comment: "" });
  const [reviewError, setReviewError] = useState(null);

  useEffect(() => {
    async function fetchProduct() {
      setError(null);
      try {
        // showLoading();
        const url = `${apiUrl}product/${productSlug}`;
        const response = await axios.get(url);
        const data = response.data;
        if (data.status) {
          setProduct(data.data.product);
          setRelatedProduct(data.data.relatedProducts);
          setLoading(false);
          await fetchReviews(data.data.product.id);
        } else {
          setError("Product not found");
          setLoading(false);
          // hideLoading();
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
        // hideLoading();
      }
    }

    async function fetchReviews(id) {
      try {
        const url = `${apiUrl}reviews/${id}`;
        const response = await axios.get(url);
        const data = response.data;
        if (data.status) {
          setReviews(data.data.reviews);
        } else {
          setReviewError("Failed to fetch reviews");
        }
      } catch (error) {
        setReviewError(error.message);
      }
    }

    if (productSlug) {
      fetchProduct();
    }
  }, [apiUrl, productSlug]);

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (isAuth == false || token == null) {
      setReviewError("Please login first.");
      return;
    }
    setReviewError(null);
    try {
      setLoading(true);
      const url = `${apiUrl}reviews`;
      const response = await axios.post(
        url,
        {
          product_id: product.id,
          comment: newReview.comment,
          rating: newReview.rating,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Assuming you store your auth token in localStorage
          },
        }
      );
      const data = response.data;
      if (data.status) {
        setReviews([...reviews, data.data.review]);
        setNewReview({ rating: "", comment: "" });
      } else {
        setReviewError(data.message);
      }
    } catch (error) {
      setReviewError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteReview = async (reviewId, reviewerId) => {
    if (isAuth == false || token == null) {
      setReviewError("Please login first.");
      return;
    }
    if (user.id != reviewerId) {
      setReviewError("You do not have permission to do this action.");
      return;
    }
    try {
      setLoading(true);
      await axios.delete(`${apiUrl}reviews/${reviewId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReviews(reviews.filter((review) => review.id !== reviewId));
    } catch (error) {
      setReviewError("Error deleting review:".error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <Container>
        <div className="card sn-card-preloader border-0 my-3">
          <div className="row">
            <div className="col-md-4">
              <div className="card-img-top" style={{ height: "300px" }}></div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <div
                    className="card-img-top"
                    style={{ height: "100px" }}
                  ></div>
                </div>
                <div className="col-md-4">
                  <div
                    className="card-img-top"
                    style={{ height: "100px" }}
                  ></div>
                </div>
                <div className="col-md-4">
                  <div
                    className="card-img-top"
                    style={{ height: "100px" }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5
                  className="card-title placeholder-title"
                  style={{ height: "40px" }}
                >
                  {" "}
                </h5>
                <h5
                  className="card-title placeholder-title w-50 mb-4"
                  style={{ height: "40px" }}
                >
                  {" "}
                </h5>
                <p className="card-text placeholder-text mb-4"> </p>
                <div className="d-flex justify-content-between mb-4">
                  <span className="placeholder-text"> </span>
                  <span className="placeholder-text"> </span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <span className="placeholder-text"> </span>
                  <span className="placeholder-text"> </span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <span className="placeholder-text" style={{ height: "40px" }}>
                    {" "}
                  </span>
                  <span className="placeholder-text" style={{ height: "40px" }}>
                    {" "}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <span className="placeholder-text"> </span>
                  <span className="placeholder-text"> </span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <span className="placeholder-text"> </span>
                  <span className="placeholder-text"> </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p className="card-text placeholder-text mb-3 w-100"> </p>
              <p className="card-text placeholder-text mb-3 w-100"> </p>
              <p className="card-text placeholder-text mb-3 w-100"> </p>
              <p className="card-text placeholder-text mb-3 w-100"> </p>
              <p className="card-text placeholder-text mb-3 w-100"> </p>
              <p className="card-text placeholder-text mb-3 w-100"> </p>
              <p className="card-text placeholder-text mb-3 w-100"> </p>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const price = product.price ? parseFloat(product.price) : 0;
  const discount = product.discount ? parseFloat(product.discount) : 0;
  const discountedPrice = price - (price * discount) / 100;

  const handleAddToCart = () => {
    const maxQty = product.stock_quantity;
    const minQty = 1;
    if (maxQty < quantity) {
      return toast.error("Allowed max quantity: " + maxQty);
    }
    if (minQty > quantity) {
      return toast.error("Allowed min quantity: " + minQty);
    }

    const addCrt = addToCart(product.slug, quantity, product);
    if (addCrt == null) toast.success("Cart added successfully!");
    else toast.error(addCrt);
  };
  const handleQtyChange = (e) => {
    const maxQty = product.stock_quantity;
    const minQty = 0;
    const selectedQty = e.target.value;
    if (maxQty < selectedQty) {
      return toast.error("Allowed max quantity: " + maxQty);
    }
    if (minQty > selectedQty) {
      return toast.error("Allowed min quantity: " + minQty);
    }
    setQuantity(e.target.value);
  };
  const handleBuyNow = () => {
    const maxQty = product.stock_quantity;
    const minQty = 1;
    if (maxQty < quantity) {
      return toast.error("Allowed max quantity: " + maxQty);
    }
    if (minQty > quantity) {
      return toast.error("Allowed min quantity: " + minQty);
    }
    const addCrt = buyNowCart(product.slug, quantity, product);
    navigate("/my/cart/checkout");
    if (addCrt == null) toast.success("Cart added successfully!");
  };
  return (
    <div className="container my-5">
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="row">
        {/* Product gallery section */}
        <div className="col-md-5">
          <ProductGallery
            featureImage={product.feature_image}
            gallery={product.images}
          />
        </div>

        {/* Product information section */}
        <div className="col-md-7">
          <div className="main-description px-2">
            {/* Dynamic product title */}
            <div className="product-title text-bold mb-2 pt-0">
              {product.title}
            </div>
            {discount > 0 ? (
              <div className="price-area my-4">
                <p className="old-price mb-1">
                  <del>
                    {currency}
                    {price}
                  </del>
                  <span className="old-price-discount pl-2 d-inline-block text-danger">
                    ({discount}% off)
                  </span>
                </p>
                {/* Assuming there's a discounted price */}
                <p className="new-price text-bold mb-1">
                  {currency}
                  {discountedPrice}
                </p>
                <p className="text-secondary mb-1">
                  (Additional tax may apply on checkout)
                </p>
              </div>
            ) : (
              <div className="price-area my-4">
                <p className="new-price text-bold mb-1">
                  {currency}
                  {product.price - product.discount}
                </p>
                <p className="text-secondary mb-1">
                  (Additional tax may apply on checkout)
                </p>
              </div>
            )}

            {/* Add to cart button */}
            {product.stock_quantity > 0 && (
              <div className="buttons d-flex my-5">
                <div className="block">
                  <Button
                    onClick={handleBuyNow}
                    style={{ maxWidth: "100px" }}
                    className="shadow btn custom-btn "
                  >
                    Buy Now
                  </Button>
                </div>
                {isProductInCart(productSlug) ? (
                  <>
                    <button
                      className="shadow btn custom-btn bg-success text-light"
                      disabled
                    >
                      Already in cart
                    </button>
                    <div className="block quantity">
                      <input
                        type="number"
                        className="form-control"
                        id="cart_quantity"
                        value={quantity}
                        min="0"
                        placeholder="Enter Quantity"
                        name="cart_quantity"
                        readOnly
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="shadow btn custom-btn"
                      onClick={handleAddToCart}
                    >
                      Add to cart
                    </button>
                    <div className="block quantity">
                      <input
                        type="number"
                        className="form-control"
                        id="cart_quantity"
                        value={quantity}
                        min="0"
                        placeholder="Enter Quantity"
                        name="cart_quantity"
                        onChange={handleQtyChange}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {product.filters.length > 0 ? (
            <div className="product-details my-4">
              <p className="details-title text-color mb-2">Specifications</p>
              <ul>
                {product.filters.map((spec, index) => (
                  <li key={index}>
                    <strong>{spec.name}:</strong>{" "}
                    {spec.pivot ? spec.pivot.value : ""}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}

          <div className="product-details my-4">
            <p className="details-title text-color mb-1">Product Details</p>
            <section
              className="description"
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></section>
          </div>
        </div>
      </div>
      <ReviewsSection
        reviews={reviews}
        newReview={newReview}
        handleReviewChange={handleReviewChange}
        handleReviewSubmit={handleReviewSubmit}
        reviewError={reviewError}
        handleDeleteReview={handleDeleteReview}
        user={user}
      />
      <div className="sn-main-card">
        <div className="sn-main-content">
          <h2>Related Products</h2>
          <Row>
            {relatedProducts.map((relatedProduct, index) =>
              isMobile ? (
                <Col xs={6} key={index}>
                  <ProductCard product={relatedProduct} />
                </Col>
              ) : (
                <Col md={3} sm={6} xs={6} key={index}>
                  <ProductCard product={relatedProduct} />
                </Col>
              )
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
