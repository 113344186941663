import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

function ProductGallery({ featureImage, gallery }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const containerRef = useRef(null);
  const [zoomStyle, setZoomStyle] = useState({});

  const handleMouseMove = (e) => {
    const container = containerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const mouseX = e.pageX - containerRect.left;
    const mouseY = e.pageY - containerRect.top;

    const zoomX = (mouseX / containerRect.width) * 100;
    const zoomY = (mouseY / containerRect.height) * 100;

    setZoomStyle({
      transform: `scale(1.5)`, // Apply scale transformation for zoom effect
      transformOrigin: `${zoomX}% ${zoomY}%`, // Set transform origin based on mouse position
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({}); // Reset zoom style when mouse leaves
  };

  return (
    <div className="product-gallery-container">
      {/* Main Swiper for large images */}
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {featureImage && (
          <SwiperSlide>
            <div
              className="sn-feature-image"
              ref={containerRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              style={zoomStyle} // Apply zoom style to the container
            >
              <img
                src={featureImage}
                alt="Main thumb"
                className="zoom-on-hover"
              />
            </div>
          </SwiperSlide>
        )}
        {gallery &&
          gallery.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="sn-feature-image">
                <img src={image.image_path} alt={`thumb ${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      {/* Thumbnail Swiper */}
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {featureImage && (
          <SwiperSlide>
            <div className="sn-thumbnail-image">
              <img src={featureImage} alt="Thumbnail thumb" />
            </div>
          </SwiperSlide>
        )}
        {gallery &&
          gallery.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="sn-thumbnail-image">
                <img src={image.image_path} alt={`thumb ${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default ProductGallery;
