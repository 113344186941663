import React from "react";

function PrivacyPolicy() {
  return (
    <section className="sn-privacy-policy  pb-5 pt-4">
      <div className="container">
        <h1 className="mb-4">Privacy Policy</h1>

        <div class="wpb_wrapper">
          <div>
            <p class="txt">
              Sak Shoppers collects and processes your personal data when you
              visit our website and mobile applications for the following
              reasons.
            </p>
            <ol id="q2" class="lst">
              <li>Process orders</li>
              <li>Process payments for your orders and seasonal offers</li>
              <li>Deliver products and services</li>
              <li>Prevent and detect fraud on our website</li>
            </ol>
          </div>
          <div>
            <p class="txt">
              We collect, use and store the following different kinds of
              personal data about you:
            </p>
            <ol class="lst">
              <li>
                Information you provide to us including your contact data,
                delivery address and financial data.
              </li>
              <li>
                We automatically collect and store information about your
                searches, views and purchases on our website and/or mobile
                applications.
              </li>
              <li>
                Information from third parties and publicly available sources:
                We may receive information about you from third parties
                including our carriers; payment service providers;
                merchants/brands; and advertising service providers.
              </li>
            </ol>
          </div>
          <div>
            <p>
              We may need to share your personal data with third parties for
              sale and delivery of products, detecting fraud and to comply with
              the law.
            </p>
            <p>
              When we share your personal data with third parties we require
              them to agree to use your data in accordance with the law and only
              permit them to process your personal data for specified purposes
              and in accordance with our instructions.
            </p>
          </div>
          <div>
            <p class="txt">
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorised way, altered or disclosed.
            </p>
            <p>
              We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable
              regulator of a breach where we are legally required to do so.
            </p>
          </div>
          <div>
            <p>
              It is important that the personal data we hold about you is
              accurate and current. Please keep us informed if your personal
              data changes during your relationship with us.
            </p>
            <p>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data, including the right to
              access, correct or erase your personal data, object to or restrict
              processing of your personal data, and unsubscribe from our emails
              and newsletters.
            </p>
          </div>
          <div>
            <p>
              If you are looking for more information on how we process your
              personal data, or you wish to exercise your legal rights in
              respect of your personal data, please Send an Email
              to&nbsp;info@sakshoppers.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
