import { Home, People, ShoppingCart } from "@mui/icons-material";
import React from "react";
import { Nav } from "react-bootstrap";

import { useLoading } from "../../Context/LoadingContext";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

function BottomNavbar() {
  const { showClickLoading } = useLoading();
  const handleClickH = () => {
    showClickLoading();
  };
  return (
    <Nav
      className="fixed-bottom sn-bottom-nav"
      fill
      id="fixed-bottom"
      justify
      variant="tabs"
      defaultActiveKey="/home"
    >
      <Nav.Item>
        <RouteLinkWithLoading to="/" className="nav-link">
          <Home /> Home
        </RouteLinkWithLoading>
      </Nav.Item>
      <Nav.Item>
        <RouteLinkWithLoading
          to="/my/cart"
          onClick={handleClickH}
          className="nav-link"
        >
          <ShoppingCart /> Cart
        </RouteLinkWithLoading>
      </Nav.Item>
      <Nav.Item>
        <RouteLinkWithLoading
          to="/my"
          onClick={handleClickH}
          className="nav-link"
        >
          <People /> Account
        </RouteLinkWithLoading>
      </Nav.Item>
    </Nav>
  );
}

export default BottomNavbar;
