import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToastMessage({ message, isError }) {
  const showTime = 5000;

  useEffect(() => {
    const notify = isError ? toast.error(message) : toast.success(message);
    console.log(notify);
    // Automatically hide the toast after showTime
    setTimeout(() => {
      toast.dismiss(notify); // Hide the toast
    }, showTime);

    return () => {
      toast.dismiss(notify); // Cleanup when component unmounts
    };
  }, [message, isError]);

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={false} // Keep it open until manually closed
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
}

export default ToastMessage;
