import React, { createContext, useContext, useEffect, useState } from "react";

// Create the Cart Context
const CartContext = createContext();

// Custom hook to consume the Cart Context
export const useCart = () => {
  return useContext(CartContext);
};

// Cart Context Provider Component
export const CartProvider = ({ children }) => {
  // State to manage the cart items
  const [cartItems, setCartItems] = useState([]);

  // Load cart items from sessionStorage on component mount
  useEffect(() => {
    const storedCartItems = sessionStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  // Save cart items to sessionStorage whenever cartItems state changes
  useEffect(() => {
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  // Function to add an item to the cart
  const addToCart = (productSlug, quantity, product) => {
    // Check if the item is already in the cart
    const existingItem = cartItems.find(
      (item) => item.productSlug === productSlug
    );
    if (existingItem) {
      // If the item exists, update its quantity
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productSlug === productSlug
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      );
      return "Item already exists!";
    } else {
      // If the item is new, add it to the cart
      setCartItems((prevItems) => [
        ...prevItems,
        { productSlug, quantity, product },
      ]);
      return null;
    }
  };

  // Function to remove an item from the cart
  const removeFromCart = (productSlug) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.productSlug !== productSlug)
    );
  };
  const emptyCart = () => {
    setCartItems([]);
  };

  const buyNowCart = (productSlug, quantity, product) => {
    setCartItems([]);
    const existingItem = cartItems.find(
      (item) => item.productSlug === productSlug
    );
    if (existingItem) {
      // If the item exists, update its quantity
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productSlug === productSlug
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      );
      return "Item already exists!";
    } else {
      // If the item is new, add it to the cart
      setCartItems((prevItems) => [
        ...prevItems,
        { productSlug, quantity, product },
      ]);
      return null;
    }
  };

  // Function to update the quantity of an item in the cart
  const updateQuantity = (productSlug, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.productSlug === productSlug ? { ...item, quantity } : item
      )
    );
  };

  const isProductInCart = (productSlug) => {
    return cartItems.some((item) => item.productSlug === productSlug);
  };
  // Cart Context value
  const cartContextValue = {
    cartItems,
    addToCart,
    removeFromCart,
    updateQuantity,
    isProductInCart,
    emptyCart,
    buyNowCart,
  };

  // Provide the Cart Context to its children
  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
};
