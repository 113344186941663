import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductCard from "./ProductCard";
import { useBasicDetails } from "../Context/BasicDetailsContext";

function ForYouProduct() {
  const { forYouProductsCt, isMobile } = useBasicDetails();
  return (
    <section className="my-3">
      <Container>
        <div className="sn-main-card">
          <div className="sn-main-title">For You</div>
          <div className="sn-main-content">
            <Row>
              {forYouProductsCt.map((product, index) =>
                isMobile ? (
                  <Col xs={6} key={index}>
                    <ProductCard product={product} />
                  </Col>
                ) : (
                  <Col md={3} sm={6} xs={6} key={index}>
                    <ProductCard product={product} />
                  </Col>
                )
              )}
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ForYouProduct;
