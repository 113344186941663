import React, { forwardRef } from "react";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Badge, Col, Row } from "react-bootstrap";
import { useBasicDetails } from "../../Context/BasicDetailsContext";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const InvoiceDocument = forwardRef(({ formData, cartItems, checkout }, ref) => {
  const { currency } = useBasicDetails();

  const calculateItemTotal = (price, discount, quantity, coupon_discount) => {
    const discountedPrice = price - (price * discount) / 100;
    return (
      (discountedPrice - (discountedPrice * coupon_discount) / 100) * quantity
    );
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) =>
        total +
        calculateItemTotal(
          item.price,
          item.discount,
          item.quantity,
          checkout.coupon_discount ? checkout.coupon_discount : 0
        ),
      0
    );
  };
  const calculateSubTotal = () => {
    return cartItems.reduce(
      (total, item) =>
        total + calculateItemTotal(item.price, item.discount, item.quantity, 0),
      0
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} ref={ref}>
        <View style={styles.section}>
          {formData && (
            <div
              style={{
                border: "solid 1px var(--sn-border-color); padding: 10px 15px;",
              }}
            >
              <div className="sn-invoice-header mb-3">
                <h1 className="text-bold text-center">
                  Order Invoice #SN{checkout.id}{" "}
                  <Badge
                    className="bg-success d-inline-block"
                    style={{ fontSize: "20px" }}
                  >
                    {checkout.shipping_status}
                  </Badge>
                </h1>
              </div>
              <h3 className="text-bold">Customer Details</h3>
              <hr />
              <Row>
                <Col md={6}>
                  <p>First Name: {formData.first_name}</p>
                  <p>Last Name: {formData.last_name}</p>
                  <p>Email: {formData.email}</p>
                  <p>Payment Status: {checkout.payment_status}</p>
                </Col>
                <Col md={6}>
                  <p>Address: {formData.address}</p>
                  <p>City: {formData.city}</p>
                  <p>ZIP Code: {formData.zip_code}</p>
                  <p>Payment Method: {checkout.payment_method}</p>
                </Col>
              </Row>
              <h3 className="text-bold">Order Details</h3>
              <hr />
              <table className="table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Original Price</th>
                    <th>Discount</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.title}</td>
                      <td>
                        {currency}
                        {item.price}
                      </td>
                      <td>{item.discount}%</td>
                      <td>{item.quantity}</td>
                      <td>
                        {currency}
                        {calculateItemTotal(
                          item.price,
                          item.discount,
                          item.quantity,
                          0
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th colSpan={3}>Coupon Code:</th>
                    <th>{checkout.coupon_code}</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th colSpan={3}>Coupon Discount:</th>
                    <th>{checkout.coupon_discount}%</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th colSpan={3}>Sub Total: </th>
                    <th>
                      <strong>
                        {currency}
                        {calculateSubTotal().toFixed(2)}
                      </strong>
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th colSpan={3}>Shipping Charge: </th>
                    <th>
                      <strong>
                        {currency}
                        {checkout.shipping_amount}
                      </strong>
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th colSpan={3}>Total: </th>
                    <th>
                      <strong>
                        {currency}
                        {(
                          calculateTotal() +
                          parseFloat(checkout.shipping_amount)
                        ).toFixed(2)}
                      </strong>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </View>
      </Page>
    </Document>
  );
});

export default InvoiceDocument;
