import React, { useState } from "react";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

function ProductFilter({
  filters,
  setFilterValue,
  filterValue,
  setPreloading,
  handlePageChange,
}) {
  const { categories } = useBasicDetails();
  const [isCategoryOpen, setCategoryOpen] = useState(true);
  const [openSubCategories, setOpenSubCategories] = useState({});

  const handleCategoryToggle = () => {
    setCategoryOpen(!isCategoryOpen);
  };

  const handleSubCategoryToggle = (categorySlug) => {
    setOpenSubCategories((prev) => ({
      ...prev,
      [categorySlug]: !prev[categorySlug],
    }));
  };

  return (
    <div className="sn-main-card">
      <div className="sn-main-title" onClick={handleCategoryToggle}>
        Filter Product
      </div>
      <div className="sn-main-content sn-p-filter">
        {isCategoryOpen && (
          <div className="sn-filter-section">
            {categories.map((category, index) => (
              <div className="sn-filter-item" key={index}>
                <div className="sn-filter-item-header">
                  <RouteLinkWithLoading
                    to={`/category/${category.slug}`}
                    onClick={() => setPreloading(true)}
                    className="sn-filter-item-link"
                  >
                    <div className="sn-filter-cat-img">
                      <img src={category.image} alt={category.name} />
                    </div>{" "}
                    {category.name}
                  </RouteLinkWithLoading>
                  {category.children && category.children.length > 0 && (
                    <button
                      className="sn-filter-sub-toggle"
                      onClick={() => handleSubCategoryToggle(category.slug)}
                    >
                      {openSubCategories[category.slug] ? (
                        <ArrowDropDown />
                      ) : (
                        <ArrowRight />
                      )}
                    </button>
                  )}
                </div>
                {openSubCategories[category.slug] && (
                  <div className="sn-filter-sub sn-show">
                    {category.children.map((subCat, subIndex) => (
                      <div className="sn-filter-item" key={subIndex}>
                        <div className="sn-filter-item-header">
                          <RouteLinkWithLoading
                            to={`/category/${subCat.slug}`}
                            className="sn-filter-item-link"
                            onClick={() => setPreloading(true)}
                          >
                            <ArrowRight /> {subCat.name}
                          </RouteLinkWithLoading>
                          {subCat.second_children &&
                            subCat.second_children.length > 0 && (
                              <button
                                className="sn-filter-sub-toggle"
                                onClick={() =>
                                  handleSubCategoryToggle(subCat.slug)
                                }
                              >
                                {openSubCategories[subCat.slug] ? (
                                  <ArrowDropDown />
                                ) : (
                                  <ArrowRight />
                                )}
                              </button>
                            )}
                        </div>
                        {openSubCategories[subCat.slug] && (
                          <div className="sn-filter-sec-sub sn-show">
                            {subCat.second_children.map(
                              (secSubCat, secIndex) => (
                                <div className="sn-filter-item" key={secIndex}>
                                  <RouteLinkWithLoading
                                    to={`/category/${secSubCat.slug}`}
                                    className="sn-filter-item-link"
                                    onClick={() => setPreloading(true)}
                                  >
                                    <ArrowRight /> {secSubCat.name}
                                  </RouteLinkWithLoading>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="sn-filter-section">
          {Object.keys(filters).map((filterName, filterIndex) => {
            const filter = filters[filterName];
            return (
              <div className="sn-filter-item" key={filterIndex}>
                <h3>{filter.name}</h3>
                <div className="sn-filter-values">
                  {filter.values.map((value, valueIndex) => (
                    <div className="sn-filter-value-item" key={valueIndex}>
                      <input
                        type="checkbox"
                        id={`${filter.name}-${valueIndex}`}
                        name={filter.name}
                        value={value}
                        checked={filterValue.some(
                          (item) => item[filter.name] === value
                        )}
                        onChange={() => {
                          setFilterValue((prevFilterValue) => {
                            const existingFilter = prevFilterValue.find(
                              (item) => item[filter.name] === value
                            );

                            if (existingFilter) {
                              return prevFilterValue.filter(
                                (item) => item !== existingFilter
                              );
                            } else {
                              return [
                                ...prevFilterValue,
                                { [filter.name]: value },
                              ];
                            }
                          });
                        }}
                      />
                      <label
                        htmlFor={`${filter.name}-${valueIndex}`}
                        className="ps-2"
                      >
                        {value}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="btn btn-primary text-center w-100"
          type="button"
          onClick={() => handlePageChange()}
        >
          <i className="fa fa-filter" aria-hidden="true"></i> Filter
        </button>
      </div>
    </div>
  );
}

export default ProductFilter;
