import React from "react";

function HowToBuy() {
  return (
    <section className="sn-how-to-buy  pb-5 pt-4">
      <div className="container">
        <h1 className="mb-4">How to Buy</h1>
        <p>
          Shopping on Sakshoppers is easy! Once you have found the product you
          want to buy, just follow the steps below:
          <br />
          • Click on the product of interest to add this product to your cart
          <br />
          • Click on ‘Cart’ in the top right corner
          <br />
          • Enter your Billing information
          <br />
          • Choose your preferred payment option
          <br />• Click on ‘Confirm Order’ to proceed to the payment portal and
          complete your order.
        </p>
        <p>
          Or simply call +256 (0) 706 386 558 to place an order. Once your order
          is placed, we will either automatically confirm it by notifying you
          via email, or we will call you for confirmation in case we need more
          details. Please note that this confirmation is a mandatory step before
          we ship your order. In case you have a doubt about whether the
          confirmation was done or not, do not hesitate to call us a few hours
          after your order placement.
        </p>
      </div>
    </section>
  );
}

export default HowToBuy;
