import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
import { useAuth } from "../../Context/AuthContext";
import RouteLinkWithLoading from "../../Components/RouteLinkWithLoading";

const Register = () => {
  const { apiUrl } = useBasicDetails();
  const { token } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!name || !email || !password || !confirmPassword) {
      toast.error("Please fill in all fields.");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(apiUrl + "register", {
        name,
        email,
        password,
      });
      const datas = response.data;
      console.log(datas);
      if (datas.status === true) {
        toast.success("Registration successful");
        navigate("/login");
      } else {
        const errors = datas.data.errors;
        Object.values(errors).forEach((error) => {
          toast.error(error[0]); // Assuming errors is an associative array
        });
      }
    } catch (error) {
      console.error("Registration failed:", error);
      toast.error("Registration failed. Please try again.");
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/my");
    }
  }, [token]);
  return (
    <div className="sn-background-img py-5">
      <div className="container">
        <div className="sn-auth-container">
          <div className="sn-auth-cotain">
            <h1 className="mb-4">Register</h1>
            {token ? (
              <p>You are already logged in. Redirecting...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>

                <Button variant="primary" onClick={handleRegister}>
                  Register
                </Button>
                <p>
                  Already an account{" "}
                  <RouteLinkWithLoading
                    to={"/login"}
                    className="d-inline-block"
                  >
                    Login?
                  </RouteLinkWithLoading>
                </p>
              </Form>
            )}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
