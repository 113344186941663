import React, { useEffect, useState } from "react";

import { useBasicDetails } from "../Context/BasicDetailsContext";
import RouteLinkWithLoading from "./RouteLinkWithLoading";

function ProductCard({ product }) {
  const { currency } = useBasicDetails();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, []);

  if (loading) {
    // preloader with card structure
    return (
      <div className="card sn-card-preloader">
        <div className="card-img-top"> </div>
        <div className="card-body">
          <h5 className="card-title placeholder-title"> </h5>
          <p className="card-text placeholder-text"> </p>
          <div className="d-flex justify-content-between">
            <span className="placeholder-text"> </span>
            <span className="placeholder-text"> </span>
          </div>
        </div>
      </div>
    );
  }
  const slicedTitle =
    product.title.length > 50
      ? product.title.slice(0, 50) + "..."
      : product.title;
  const price = product.price ? parseFloat(product.price) : 0;
  const discount = product.discount ? parseFloat(product.discount) : 0;
  const discountedPrice = price - (price * discount) / 100;

  return (
    <RouteLinkWithLoading
      to={`/product/${product.slug}`}
      className="card sn-product-card"
    >
      <div className="sn-card-img">
        <img
          src={product.feature_image}
          className="card-img-top"
          alt={product.title}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title">{slicedTitle}</h5>

        <div className="d-block d-md-flex justify-content-between">
          {discount > 0 ? (
            <>
              <del className="d-block">
                {" "}
                <span className="text-muted">
                  {currency}
                  {price}
                </span>
              </del>
              <span className="text-success">
                {currency}
                {discountedPrice}
              </span>
            </>
          ) : (
            <span className="text-muted">
              {currency}
              {price}
            </span>
          )}
        </div>
      </div>
      {discount > 0 ? (
        <div className="sn-offer-badge">{discount}% off</div>
      ) : (
        ""
      )}
    </RouteLinkWithLoading>
  );
}

export default ProductCard;
