import React, { useEffect, useState } from "react";
import { useBasicDetails } from "../../Context/BasicDetailsContext";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileCard from "./ProfileCard";
import MyCheckoutTable from "./UserComponents/MyCheckoutTable";
import { Typography } from "@mui/material";

function MyOrders() {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true); // Local loading state
  const { apiUrl } = useBasicDetails();
  const { token } = useAuth();
  const fetchOrders = async () => {
    try {
      setLoading(true); // Set loading to true before fetching orders
      const response = await axios.get(apiUrl + "user/orders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = response.data;
      if (responseData.status) {
        setOrders(responseData.data.orders);
      } else {
        toast.error("Failed to fetch orders: " + responseData.message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Failed to fetch orders. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after fetching orders
    }
  };
  useEffect(() => {
    fetchOrders(); // Fetch orders when component mounts
  }, []);

  return (
    <Container>
      <ToastContainer />
      <Row className="mt-4">
        <Col md={12}>
          <h1 className="mb-3">My Account</h1>
          <hr />
        </Col>
        <Col xs={12} md={4} className="sn-myac-sidebar">
          <ProfileCard />
        </Col>

        <Col xs={12} md={8} className="pt-5">
          <h3>My Orders</h3>
          {loading || !orders ? (
            <Typography variant="body1" align="center">
              Loading...
            </Typography>
          ) : (
            <MyCheckoutTable
              orders={orders}
              setLoading={setLoading}
              fetchOrders={fetchOrders}
            /> // Render the new component with orders data
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default MyOrders;
