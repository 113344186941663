import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../../Context/LoadingContext";
import axios from "axios";
import { useBasicDetails } from "../../../Context/BasicDetailsContext";
import { useAuth } from "../../../Context/AuthContext";

function MyCheckoutTable({ orders, setLoading, fetchOrders }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const { apiUrl } = useBasicDetails();
  const { token } = useAuth();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy HH:mm:ss");
  };

  const handleViewOrder = (orderId) => {
    const submitOrder = async () => {
      showLoading();
      try {
        const response = await axios.get(apiUrl + "user/order/" + orderId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = response.data;
        if (responseData.status) {
          sessionStorage.setItem("checkout", JSON.stringify(responseData.data));
          navigate("/my/invoice");
        } else {
          toast.error("Failed to load order: " + responseData.message);
        }
      } catch (error) {
        // Error occurred while making the request
        console.error("Error placing order:", error);
        toast.error("Failed to load order. Please try again later.");
      } finally {
        setTimeout(() => {
          hideLoading();
        }, 1000);
      }
    };

    // Call the submitOrder function to initiate the checkout process
    submitOrder();
  };

  const handleCancelOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setIsCancelDialogOpen(true);
  };

  const confirmCancelOrder = async () => {
    setIsCancelDialogOpen(false);
    setLoading(true); // Set loading to true while API call is in progress
    try {
      const response = await axios.post(
        `${apiUrl}user/order/${selectedOrderId}/cancel`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = response.data;
      if (responseData.status) {
        // Handle success, maybe update UI accordingly
        toast.success("Order cancelled successfully.");
      } else {
        toast.error("Failed to cancel order: " + responseData.message);
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
      toast.error("Failed to cancel order. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after API call completes
      fetchOrders();
    }
  };

  const cancelCancelOrder = () => {
    setIsCancelDialogOpen(false);
    setSelectedOrderId(null);
  };

  const minWidth = "160px";

  const columns = [
    { id: "actions", label: "Actions" },
    { id: "total_price", label: "Total Price", minWidth: minWidth },
    { id: "payment_method", label: "Payment Method", minWidth: minWidth },
    { id: "payment_status", label: "Payment Status", minWidth: minWidth },
    { id: "shipping_status", label: "Shipping Status", minWidth: minWidth },
    { id: "shipping_amount", label: "Shipping Amount", minWidth: minWidth },
    { id: "created_at", label: "Date/Time", minWidth: minWidth },
  ];

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <ToastContainer />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>
                      {order.shipping_status === "new" && (
                        <>
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() => handleViewOrder(order.id)}
                          >
                            View
                          </Button>
                          <Button
                            variant="danger"
                            type="button"
                            onClick={() => handleCancelOrder(order.id)}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                      {order.shipping_status !== "new" && (
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() => handleViewOrder(order.id)}
                        >
                          View
                        </Button>
                      )}
                    </TableCell>

                    <TableCell>{order.total_price}</TableCell>
                    <TableCell>{order.payment_method}</TableCell>
                    <TableCell>{order.payment_status}</TableCell>
                    <TableCell>{order.shipping_status}</TableCell>
                    <TableCell>{order.shipping_amount}</TableCell>
                    <TableCell>{formatDate(order.created_at)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Cancel Order Confirmation Dialog */}
      <Dialog
        open={isCancelDialogOpen}
        onClose={cancelCancelOrder}
        aria-labelledby="cancel-order-dialog-title"
        aria-describedby="cancel-order-dialog-description"
      >
        <DialogTitle id="cancel-order-dialog-title">
          Cancel Order Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-order-dialog-description">
            Are you sure you want to cancel this order? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="danger" onClick={confirmCancelOrder}>
            Yes, Cancel Order
          </Button>
          <Button onClick={cancelCancelOrder}>No, Keep Order</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MyCheckoutTable;
