import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TestimonialCard from "./TestimonialCard";

const TestimonialSection = () => {
  // Dummy testimonial data (replace with actual data)
  const testimonials = [
    {
      id: 1,
      author: "John Doe",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 2,
      author: "Jane Smith",
      text: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    // Add more testimonials as needed
  ];

  return (
    <section className="my-3">
      <Container>
        <div className="sn-main-card">
          <div className="sn-main-title">What client Says.</div>
          <div className="sn-main-content">
            <Row>
              {testimonials.map((testimonial) => (
                <Col key={testimonial.id} md={6} className="">
                  <TestimonialCard testimonial={testimonial} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TestimonialSection;
